<template>
  <div>
    <!--begin::Forgot Password-->
    <div class="login-form login-forgot">
      <!--begin::Title-->
      <div class="pb-5 pt-lg-0 pt-5">
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Select Your Company</h3>
          <p class="text-muted font-weight-bold font-size-h4">Select the company to process the data</p>
      </div>
      <!--end::Title-->

      <b-alert :show="(errors ? true : false)" fade>{{ errors }}</b-alert>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-form-group
          id="form-input-group-2"
          label-class="font-size-h6 font-weight-bolder text-dark pt-5"
          label="Komoditas"
          label-for="form-input-2"
        >
          <b-form-select2
            v-model="$v.form.komoditas.$model"
            id="form-input-2"
            name="form-input-2"
            variant="solid"
            size="lg"
            :options="opsKomoditas"
            :state="validateState('komoditas')"
            aria-describedby="input-2-live-feedback"
          >
          </b-form-select2>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Komoditas is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="form-input-group-3"
          label-class="font-size-h6 font-weight-bolder text-dark"
          label="Tipe"
          label-for="form-input-3"
        >
          <b-form-select2
            v-model="$v.form.tipe.$model"
            id="form-input-3"
            variant="solid"
            size="lg"
            :options="opsTipe"
            name="form-input-3"
            :state="validateState('tipe')"
            aria-describedby="input-3-live-feedback"
          >
          </b-form-select2>

          <b-form-invalid-feedback id="input-3-live-feedback">
            Tipe is required.
          </b-form-invalid-feedback>
        </b-form-group>
        
        <b-form-group
          v-if="!form.cmp || form.cmp != 'ya'"
          id="form-input-group-1"
          label-class="font-size-h6 font-weight-bolder text-dark"
          label="Company"
          label-for="form-input-1"
        >
          <b-form-select2
            v-model="$v.form.perusahaan.$model" 
            id="form-input-1"
            variant="solid"
            size="lg"
            :options="opsPerusahaan"
            name="form-input-1"
            :state="validateState('perusahaan')"
            aria-describedby="input-1-live-feedback"
          >
          </b-form-select2>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Company is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap pb-lg-0 mb-0"
        >
          <button
            ref="kt_select_company_submit"
            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          >
            Submit
          </button>
          
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Forgot Password-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { SCOMPANY } from "@/core/services/store/auth.module";
import ErrorService from "@/core/services/error.service";
import ProfileService from "@/core/services/api/profile.service";

import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "select-company",
  data() {
    return {
      form: {
        komoditas: null,
        tipe: null,
        perusahaan: null,
        cmp: null
      },
      opsKomoditas: [{
        value: null,
        text: "Select Komoditas"
      }],
      opsTipe: [{
        value: null,
        text: "Select Tipe"
      }],
      opsPerusahaan: [{
        value: null,
        text: "Select Your Company"
      }]
    };
  },
  validations: {
    form: {
      komoditas: {
        required
      },
      tipe: {
        required
      },
      perusahaan: {
        required: requiredIf(function (value) {
          return value && !this.form.cmp || value && this.form.cmp != 'ya'
        })
      },
    }
  },
  watch: {
    'form.komoditas' (val, old) {
      if (val != old) {
        this.form.tipe = null
        this.form.perusahaan = null
        this.getTipe(val)
      }
    },
    'form.tipe' (val, old) {
      if (val != old) {
        if (!this.form.cmp || this.form.cmp != 'ya') {
          this.form.perusahaan = null
          this.getPerusahaan(val)
        }
      }
    },
  },
  methods: {
    getKomoditas() {
      ProfileService.getKomoditasAkses()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.opsKomoditas = res.data.data
            this.opsKomoditas.push({
              value: null,
              text: "Select Komoditas"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKomoditas = [{
            value: null,
            text: "Tidak memiliki Akses Komoditas"
          }]
        })
    },
    getTipe(value) {
      if (!value) {
        return
      }
      ProfileService.getTipeAkses({komoditas: value})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.opsTipe = res.data.data
            this.opsTipe.push({
              value: null,
              text: "Select Tipe"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsTipe = [{
            value: null,
            text: "Tidak memiliki Akses Tipe"
          }]
        })
    },
    getPerusahaan(value) {
      if (!value) {
        return
      }
      ProfileService.getCompanyAkses({komoditas: this.form.komoditas, tipe: value})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
              value: null,
              text: "Select Your Company"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsPerusahaan = [{
            value: null,
            text: "Tidak memiliki Akses Perusahaan"
          }]
        })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    resetForm() {
      this.form = {
        komoditas: null,
        tipe: null,
        cmp: null,
        perusahaan: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const komoditas = this.$v.form.komoditas.$model;
      const tipe = this.$v.form.tipe.$model;
      const perusahaan = this.$v.form.perusahaan.$model;
      const cmp = this.form.cmp;

      // set spinner to submit button
      const submitButton = this.$refs["kt_select_company_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // setTimeout(() => {
        // send login request
        this.$store
          .dispatch(SCOMPANY, { komoditas, tipe, perusahaan, cmp })
          // go to which page after successfully login
          .then(() => {
            this.removeSpinner(submitButton)
            if (this.$route.query.rget && this.$route.query.rget != '/auth/select-company') {
              return this.$router.push({ path: this.$route.query.rget }).catch(()=>{}) 
            }
            this.$router.push({ name: "dashboard" }).catch(()=>{})
          })
          .catch((err) => {
            this.removeSpinner(submitButton)
            ErrorService.status(err)
          })
      // }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
  },
  mounted() {
    this.getKomoditas()
    if (this.$route.query.cmp) {
      this.form.cmp = this.$route.query.cmp
    }
  },
};
</script>
